import { Container, Table } from "react-bootstrap";
import seal from "../../images/Seal.png";


const PriceChart = ({ priceChartTableHeading }) => {
    return (
        <div>
            <Container style={{ position: "relative" }}>
                <h2
                    style={{
                        textAlign: "center",
                        marginBottom: "40px",
                        color: "#24457b",
                        fontWeight: "bold",
                        fontSize: "30px",
                    }}
                >
                    Price Chart
                </h2>
                <img
                    src={seal}
                    className="price-seals"
                    alt="Seal"
                    title="Seal"
                    style={{
                        position: "absolute",
                        top: "67%",
                        left: "48%",
                        transform: "translate(-50%, -50%)",
                        opacity: 0.8, // Optional: makes it look more like a watermark
                        zIndex: 1,
                    }}
                />
                <Table className="price-table" style={{ "opacity": 0.5 }} responsive>
                    <thead>
                        <tr>
                            {priceChartTableHeading.map((ele, i) => {
                                return <th key={i}>{ele}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>30X40</td>
                            <td>949/-</td>
                            <td>11,38,800</td>
                            <td>3,41,640</td>
                            <td>3,41,640</td>
                            <td>2,27,760</td>
                            <td>2,27,760</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>30X50</td>
                            <td>949/-</td>
                            <td>14,23,500</td>
                            <td>4,27,050</td>
                            <td>4,27,050</td>
                            <td>2,84,700</td>
                            <td>2,84,700</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>40X60</td>
                            <td>949/- </td>
                            <td>22,77,600</td>
                            <td>6,83,280</td>
                            <td>6,83,280</td>
                            <td>4,55,520</td>
                            <td>4,55,520</td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
            <Container>
                <Table className="price-table mt-5" hover responsive>
                    <thead>
                        <tr>
                            {priceChartTableHeading.map((ele, i) => {
                                return <th key={i}>{ele}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>30X40</td>
                            <td>1099/-</td>
                            <td>13,18,800</td>
                            <td>3,95,640</td>
                            <td>3,95,640</td>
                            <td>2,63,760</td>
                            <td>2,63,760</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>30X50</td>
                            <td>1099/-</td>
                            <td>16,48,500</td>
                            <td>4,94,550</td>
                            <td>4,94,550</td>
                            <td>3,29,700</td>
                            <td>3,29,700</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>40X60</td>
                            <td>1099/-</td>
                            <td>26,37,600</td>
                            <td>7,91,280</td>
                            <td>7,91,280</td>
                            <td>5,27,520</td>
                            <td>5,27,520</td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default PriceChart;