import { Container, Table } from "react-bootstrap";

const PriceChart = () => {
    return (
        <div>
            <Container>
                <h2 style={{ textAlign: 'center', marginBottom: '40px', marginTop: '40px', color: '#24457b', fontWeight: 'bold', fontSize: '30px' }}>Price Chart</h2>
                <Table className="price-table" hover responsive>
                    <thead>
                        <tr >
                            <th>Dimension</th>
                            <th>Rate per Sqft</th>
                            <th>Total Amount</th>
                            <th>Down Payment 30%</th>
                            <th>First Installment 30%</th>
                            <th>Second Installment 20%</th>
                            <th>Third Installment 20%</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>30X40</td>
                            <td>1399/-</td>
                            <td>16,78,800</td>
                            <td>5,03,640</td>
                            <td>5,03,640</td>
                            <td>3,35,760</td>
                            <td>3,35,760</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>30X50</td>
                            <td>1399/-</td>
                            <td>20,98,500</td>
                            <td>6,29,550</td>
                            <td>6,29,550</td>
                            <td>4,19,700</td>
                            <td>4,19,700</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>40X60</td>
                            <td>1399/-</td>
                            <td>33,57,600</td>
                            <td>10,07,280</td>
                            <td>10,07,280</td>
                            <td>6,71,520</td>
                            <td>6,71,520</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>50X80</td>
                            <td>1399/-</td>
                            <td>55,96,000</td>
                            <td>16,78,800</td>
                            <td>16,78,800</td>
                            <td>11,19,200</td>
                            <td>11,19,200</td>
                        </tr>
                        <tr>
                            <td style={{ fontWeight: "bold" }}>100X120</td>
                            <td>1399/-</td>
                            <td>1,67,88,000</td>
                            <td>50,36,400</td>
                            <td>50,36,400</td>
                            <td>33,57,600</td>
                            <td>33,57,600</td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}

export default PriceChart;